<template>
    <div class="form-access">
        <el-form
            @submit.native.prevent
            ref="passwordResetSetForm"
            :model="passwordResetSetForm"
            :rules="passwordResetSetFormRules" >
            <div class="mb-3">
                <img class="img-fluid" src="./../../assets/img/infibo-logo.svg" alt="inFibo Logo">
            </div>
            <p class="text-muted text-center mb-3">{{ $t('setNewPassword') }}</p>
            <el-form-item prop="email">
                <el-input
                    disabled
                    class="regular-color"
                    clearable
                    v-model="passwordResetSetForm.email"
                    @keyup.enter.native="submitPasswordResetSetForm"/>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    clearable
                    show-password
                    v-model="passwordResetSetForm.password"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterNewPassword')"
                    @keyup.enter.native="submitPasswordResetSetForm"/>
            </el-form-item>
            <el-form-item prop="password_confirmation">
                <el-input
                    clearable
                    show-password
                    v-model="passwordResetSetForm.password_confirmation"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterNewPasswordConfirmation')"
                    @keyup.enter.native="submitPasswordResetSetForm"/>
            </el-form-item>
            <el-form-item>
                <el-button
                    class="w-100"
                    type="primary"
                    @click="submitPasswordResetSetForm"
                    :disabled="profileLoadingState"
                    :loading="profileLoadingState">{{ $t('save') }}
                </el-button>
            </el-form-item>
        </el-form>
        <div class="form-group text-center">
            <router-link class="text-muted text-decoration-none" to="/login">{{
                    $t('goToLogin')
                }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordResetSet',
    i18n: {
        messages: {
            en: {
                setNewPassword: 'Set new password',
                enterNewPassword: 'Enter new password',
                enterNewPasswordConfirmation: 'Enter new password confirmation',
                passwordsDontMatch: 'Passwords dont match',
                save: 'Save',
                goToLogin: 'Go to login',

                error: 'Error',
                passwordChanged: 'Password has been changed',
                passwordChangeError: 'Password change error',
            },
            ru: {
                setNewPassword: 'Установка нового пароля',
                enterNewPassword: 'Введите новый пароль',
                enterNewPasswordConfirmation: 'Введите подтверждение нового пароля',
                passwordsDontMatch: 'Пароли не совпадают',
                save: 'Сохранить',
                goToLogin: 'Перейти ко входу',

                error: 'Ошибка',
                passwordChanged: 'Пароль изменен',
                passwordChangeError: 'Ошибка изменения пароля',
            }
        }
    },
    data: function () {
        const validatePasswordConfirmation = (rule, value, callback) => {
            if(value === '') {
                callback(new Error(String(this.$t('enterNewPasswordConfirmation'))))
            } else if(value !== this.passwordResetSetForm.password) {
                callback(new Error(String(this.$t('passwordsDontMatch'))))
            } else {
                callback()
            }
        }
        return {
            passwordResetSetForm: {
                email: '',
                token: '',
                password: '',
                password_confirmation: ''
            },
            passwordResetSetFormRules: {
                password: [
                    {
                        required: true,
                        message: () => this.$t('enterNewPassword'),
                    }
                ],
                password_confirmation: [
                    {
                        validator: validatePasswordConfirmation,
                    }
                ],
            }
        }
    },
    computed: {
        profileLoadingState: function () {
            return this.$store.getters.PROFILE_LOADING_STATE
        }
    },
    methods: {
        submitPasswordResetSetForm: function () {
            this.$refs['passwordResetSetForm'].validate(valid => {
                if(!valid) {
                    return
                }

                this.$store.dispatch('PROFILE_SET_NEW_PASSWORD', this.passwordResetSetForm)
                    .then(() => {
                        this.$refs['passwordResetSetForm'].resetFields()
                        this.$notify({
                            type: 'success',
                            title: 'Ok',
                            message: this.$t('passwordChanged'),
                        })
                        this.$router.push('/auth/login')
                    })
                    .catch(error => {
                        console.log(error)
                        this.$notify({
                            type: 'error',
                            title: this.$t('error'),
                            message: this.$t('passwordChangeError')
                        })
                    })
            })
        }
    },
    mounted() {
        this.passwordResetSetForm.email = this.$route.query.email
        this.passwordResetSetForm.token = this.$route.query.token
    }
}
</script>

<style scoped>
.form-access {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 14px -6px;
    border-radius: 5px;
    padding: 30px;
    border: 1px solid #f2f4f9;
    max-width: 350px;
}

.regular-color >>> .el-input__inner {
    color: #555555;
}
</style>
